<template>
    <div class="div">
        <div class="book-list">
            <template v-for="(item,index) in bookList.slice(0,1)">
                <div class="book-top-list" @click="detailsClick(item)">
                    <p class="points_relative">
                        <img v-lazy="item.coverpic" class="book-img"/>
                        <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                    </p>
                    <div>
                        <div class="book-title">
                            <p class="book-title-left">{{item.title}}</p>
                            <p class="book-title-right" v-if="item.average_score != 0">{{item.average_score}} <span
                                    class="book-title-right1">分</span>
                            </p>
                        </div>
                        <div class="book-subtitle">
                            {{item.desc}}
                        </div>
                        <div class="author-list">
                            <div class="author-icon-left">
                                <p><img src="../../assets/images/author_icon.png" alt="" class="author_icon"></p>
                                <p>{{item.author}}</p>
                            </div>
                            <div class="author-icon-left">
                                <p class="author-text">{{item.classify.name}}</p>
                                <p class="author-subtitle" v-if="item.iswz == 1">连载中</p>
                                <p class="author-subtitle" v-else>已完结</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div>
                <div class="book-button">
                    <template v-for="(item,index) in bookList.slice(1,5)">
                        <div class="book-button-div" @click="detailsClick(item)">
                            <p><img v-lazy="item.coverpic" class="book_img"/></p>
                            <template v-if="item.isvip =='0'">
                                <p class="points" v-if="item.average_score != 0">{{item.average_score}}
                                    <span>分</span></p>
                            </template>
                            <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                            <p class="title">{{item.title}}</p>
                        </div>
                    </template>
                </div>
            </div>
            <template v-for="(item,index) in bookList.slice(5,6)">
                <div class="book-top-list" @click="detailsClick(item)">
                    <p class="points_relative">
                        <img v-lazy="item.coverpic" class="book-img"/>
                        <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                    </p>
                    <div>
                        <div class="book-title">
                            <p class="book-title-left">{{item.title}}</p>
                            <p class="book-title-right" v-if="item.average_score != 0">{{item.average_score}}<span
                                    class="book-title-right1">分</span>
                            </p>
                        </div>
                        <div class="book-subtitle">
                            {{item.desc}}
                        </div>
                        <div class="author-list">
                            <div class="author-icon-left">
                                <p><img src="../../assets/images/author_icon.png" alt="" class="author_icon"></p>
                                <p>{{item.author}}</p>
                            </div>
                            <div class="author-icon-left">
                                <p class="author-text">{{item.classify.name}}</p>
                                <p class="author-subtitle" v-if="item.iswz == 1">连载中</p>
                                <p class="author-subtitle" v-else>已完结</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div>
                <div class="book-button">
                    <template v-for="(item,index) in bookList.slice(6,10)">
                        <div class="book-button-div" @click="detailsClick(item)">
                            <p><img v-lazy="item.coverpic" class="book_img"/></p>
                            <template v-if="item.isvip =='0'">
                                <p class="points" v-if="item.average_score != 0">{{item.average_score}}
                                    <span>分</span></p>
                            </template>
                            <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                            <p class="title">{{item.title}}</p>
                        </div>
                    </template>
                </div>
            </div>
            <template v-for="(item,index) in bookList.slice(10,11)">
                <div class="book-top-list" @click="detailsClick(item)">
                    <p class="points_relative">
                        <img v-lazy="item.coverpic" class="book-img"/>
                        <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                    </p>
                    <div>
                        <div class="book-title">
                            <p class="book-title-left">{{item.title}}</p>
                            <p class="book-title-right" v-if="item.average_score != 0">{{item.average_score}} <span
                                    class="book-title-right1">分</span>
                            </p>
                        </div>
                        <div class="book-subtitle">
                            {{item.desc}}
                        </div>
                        <div class="author-list">
                            <div class="author-icon-left">
                                <p><img src="../../assets/images/author_icon.png" alt="" class="author_icon"></p>
                                <p>{{item.author}}</p>
                            </div>
                            <div class="author-icon-left">
                                <p class="author-text">{{item.classify.name}}</p>
                                <p class="author-subtitle" v-if="item.iswz == 1">连载中</p>
                                <p class="author-subtitle" v-else>已完结</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div>
                <div class="book-button">
                    <template v-for="(item,index) in bookList.slice(11,15)">
                        <div class="book-button-div" @click="detailsClick(item)">
                            <p><img v-lazy="item.coverpic" class="book_img"/></p>
                            <template v-if="item.isvip =='0'">
                                <p class="points" v-if="item.average_score != 0">{{item.average_score}}
                                    <span>分</span></p>
                            </template>
                            <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                            <p class="title">{{item.title}}</p>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            bookList: Array,
        },
        name: "Typography2",
        methods: {
            detailsClick(item) {
                this.$emit('detailsClick', item)
            }
        }
    }
</script>

<style scoped>
    .title {
        font-size: 26px;
        color: #303133;
        width: 145px;
        height: 27px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 20px;
    }

    .book-button-div {
        position: relative;
        margin-right: 30px;
    }

    .book-button-div:nth-of-type(4n+4) {
        margin-right: 0 !important;
    }


    .book_img {
        width: 145px;
        height: 203px;
        border-radius: 10px;
        display: inline-block;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
    }

    .book-button {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
    }

    .author-text {
        color: #F5325E;
        font-size: 20px;
        padding: 5px 15px;
        border: 1px solid #F5325E;
        border-radius: 4px;
        margin-right: 10px;
    }

    .book-list {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid #F2F5F8;
    }

    .author-subtitle {
        color: #BFC2CC;
        font-size: 20px;
        padding: 5px 15px;
        border: 1px solid #EDEFF2;
        border-radius: 4px;
    }

    .author-icon-left {
        display: flex;
        align-items: center;
        font-size: 24px;
        color: #BFC2CC;
    }

    .author-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .author_icon {
        width: 20px;
        height: 23px;
        margin-right: 10px;
    }

    .book-subtitle {
        font-size: 24px;
        font-weight: 400;
        color: #BFC2CC;
        line-height: 36px;
        border-radius: 10px;
        height: 65px;
        width: 467px;
        margin: 30px 0 42px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .book-top-list {
        display: flex;
        align-items: center;
        margin-top: 40px;
    }

    .book-title-right {
        font-size: 36px;
        color: #FCAD0F;
    }

    .book-title-right1 {
        font-size: 30px;
    }

    .book-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 467px;
    }

    .book-title-left {
        font-size: 30px;
        color: #303133;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .book-img {
        width: 170px;
        height: 238px;
        border-radius: 10px;
        margin-right: 24px;
        display: inline-block;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
    }
</style>