import { render, staticRenderFns } from "./Typography5.vue?vue&type=template&id=699872a8&scoped=true&"
import script from "./Typography5.vue?vue&type=script&lang=js&"
export * from "./Typography5.vue?vue&type=script&lang=js&"
import style0 from "./Typography5.vue?vue&type=style&index=0&id=699872a8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "699872a8",
  null
  
)

export default component.exports