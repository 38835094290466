<template>
    <div class="div">
        <div class="book">
            <template v-for="item in bookList">
                <div class="book_list" @click="detailsClick(item)">
                    <p><img v-lazy="item.coverpic" class="book_img"/></p>
                    <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                    <template v-if="item.isvip =='0'">
                        <p class="points" v-if="item.average_score != 0">{{item.average_score}}分</p>
                    </template>
                    <div class="">
                        <p class="book_title">{{item.title}}</p>
                        <p class="book_type">{{item.classify.name}}</p>
                        <p class="book_huo">
                            <img src="../../assets/images/hot_icon.png" alt="" class="hot_icon">
                            <span>{{item.hots}}热度</span>
                        </p>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Typography1",
        props: {
            bookList: Array,
        },
        data() {
            return {}
        },
        methods: {
            detailsClick(item) {
                this.$emit('detailsClick', item)
            }
        }
    }
</script>

<style scoped>
    .book_huo {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        color: rgba(245, 50, 94, 1);
    }

    .book_type {
        font-size: 20px;
        font-weight: 500;
        color: rgba(191, 194, 204, 1);
        padding: 5px 10px;
        border: 1px solid rgba(237, 239, 242, 1);
        border-radius: 4px;
        margin: 20px 0 32px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .book_title {
        width: 128px;
        height: 70px;
        font-size: 26px;
        line-height: 35px;
        color: #303133;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /*决定哪一行截取*/
        -webkit-box-orient: vertical;
    }

    .book_list {
        display: flex;
        align-items: center;
        margin-top: 40px;
        width: 315px;
        position: relative;
    }

    .hot_icon {
        width: 17px;
        height: 20px;
        margin-right: 5px;
    }

    .book_img {
        width: 145px;
        height: 203px;
        border-radius: 10px;
        margin-right: 24px;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
        display: inline-block;
    }

    .book {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border-bottom: 1px solid #F2F5F8;
        padding-bottom: 40px;
        margin-bottom: 50px;
    }
</style>